<template>
  <div v-if="dataready">
    <div
      class="
        container-fluid
        header-content
        pos-relative
        height-res-tab-small-300 height-res-200
      "
    >
      <div class="container-standar pos-relative h-100 h-res-tab-small-80">
        <div class="pos-absolute bottom-10 ma rgin-auto text-center w-100">
          <!-- <h4 class="text-white font-28 font-weight-6 mb-10">Data Kependudukan</h4> -->
          <h4 class="text-white font-35 font-weight-7 mb-20">
            DATA KEPENDUDUKAN PROVINSI PAPUA
          </h4>
          <a class="font-14 text-white font-weight-6" href="/">Home /</a>
          <a class="font-14 text-white" href="#">Data Pokok/</a>
          <a class="font-14 text-white" href="#">Data Kependudukan</a>
        </div>
      </div>
    </div>
    <div class="container-standar bg-white mt-40 pl-10 pr-10 pb-9 mb-10">
      <JiwaSummary />
      <section>
        <div class="mb-10">
          <ChartSummary />
        </div>
      </section>
      <section>
        <div class="row">
          <div class="col-lg-12 mt-6">
            <div class="card width-100 pt-10 pb-10 shadow">
              <span
                class="
                  font-18
                  text-primary text-center
                  pl-4
                  mb-10
                  font-weight-6
                "
              >
                <strong
                  >MAP PERSEBARAN TAHUN
                  {{
                    selectYear.persebaran == "all"
                      ? "2020-2022"
                      : selectYear.persebaran
                  }}</strong
                >
              </span>

              <b-row>
                <b-col cols="12" sm="12" md="8" lg="8" class="mb-2">
                  <div class="img-res">
                    <div class="overflow-stacked d-flex justify-center">
                      <maps-component></maps-component>
                      <!-- <div class="zoom" @click="zoom()">
                        zoom
                      </div> -->
                    </div>
                    <a
                      class="
                        d-block
                        width-80
                        text-right
                        margin-auto
                        pt-2
                        font-weight-6
                      "
                      href="https://papua.bps.go.id/publication/2019/08/16/e43fc034219586add3878e48/provinsi-papua-dalam-angka-2019.html"
                      >Source: papua.bps.go.id</a
                    >
                  </div>
                </b-col>
                <b-col cols="12" sm="12" md="4" lg="4" class="pl-12 pr-12">
                  <div class="scroll-container custom-scrollbar">
                    <div class="accordion" role="tablist">
                      <b-card
                        no-body
                        class="mb-1"
                        v-for="(e, i) in data_provinsi"
                        :key="i"
                      >
                        <b-card-header
                          header-tag="header"
                          class="p-1"
                          role="tab"
                        >
                          <b-button
                            block
                            v-b-toggle="'accordion-' + i"
                            :style="{ background: e.color }"
                            >{{ e.nama }}</b-button
                          >
                        </b-card-header>
                        <b-collapse
                          :id="'accordion-' + i"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-row>
                              <b-col cols="6">
                                <b-card-text>Data Perempuan :</b-card-text>
                                <b-card-text>Data Laki-laki :</b-card-text>
                                <b-card-text>Data Keluarga :</b-card-text>
                                <b-card-text><b>Total Jiwa :</b></b-card-text>
                              </b-col>
                              <b-col cols="6" class="text-right">
                                <b>
                                  <b-card-text
                                    >{{ e.perempuan }} Orang</b-card-text
                                  >
                                  <b-card-text
                                    >{{ e.laki_laki }} Orang</b-card-text
                                  >
                                  <b-card-text
                                    >{{ e.total_keluarga }} Orang</b-card-text
                                  >
                                  <b-card-text
                                    >{{ e.total_jiwa }} Orang</b-card-text
                                  >
                                </b>
                              </b-col>
                            </b-row>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="col-lg-12 inline-flex mt-6">
            <div class="card width-100 pad-20 shadow">
              <span
                class="
                  font-24
                  text-primary text-center
                  pl-4
                  mb-20
                  font-weight-6
                "
              >
                <strong>TABEL PERSEBARAN OAP PROVINSI</strong>
              </span>
              <div class="width-100 flex justify-center mb-16">
                <b-form-select
                  v-model="selectYear.persebaran"
                  :options="yearOptions"
                  return-object
                  class="col-md-4 col-lg-3"
                ></b-form-select>
              </div>
              <table class="table table-lg table-xs">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Kabupaten</th>
                    <th scope="col">Laki Laki</th>
                    <th scope="col">Perempuan</th>
                    <th scope="col">Sub Total</th>
                    <th scope="col">Presentase dari Total OAP Provinsi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="index" v-for="(data, index, fname) in dataOAP">
                    <th>{{ fname + 1 }}</th>
                    <td>{{ data.nama_kabupaten }}</td>
                    <td>{{ data.male_count }}</td>
                    <td>{{ data.female_count }}</td>
                    <td>{{ data.subtotal }}</td>
                    <td>{{ data.percentage }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-lg-12 inline-flex mt-6">
            <div class="card width-100 shadow pad-20">
              <b-card no-body>
                <b-tabs pills card vertical nav-wrapper-class="w-25">
                  <b-tab
                    title="Data OAP dan Non OAP"
                    @click="chekedfunction(1)"
                  >
                    <b-card-text class="col-lg-12">
                      <div class="row text-center">
                        <div class="width-95 d-inline-block">
                          <h4 class="mb-20 mt-10 font-24 text-primary">
                            <strong
                              >Data OAP dan Non OAP
                              {{
                                selectYear.oap == "all"
                                  ? "2020-2022"
                                  : selectYear.oap
                              }}</strong
                            >
                          </h4>
                          <div class="width-100 flex justify-center mb-16">
                            <b-form-select
                              v-model="selectYear.oap"
                              :options="yearOptions"
                              return-object
                              class="col-md-4 col-lg-3"
                            ></b-form-select>
                          </div>
                          <pie-chart
                            :filter_tahun="selectYear.oap"
                            v-if="tabactive_1"
                          ></pie-chart>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab
                    title="Kepemilikan Adminduk"
                    @click="chekedfunction(2)"
                  >
                    <b-card-text class="col-lg-12">
                      <div class="row text-center">
                        <div class="width-95 d-inline-block">
                          <h4 class="mb-20 mt-10 font-24 text-primary">
                            <strong
                              >Data Kepemilikan Adminduk Tahun
                              {{
                                selectYear.adminduk == "all"
                                  ? "2020-2022"
                                  : selectYear.adminduk
                              }}</strong
                            >
                          </h4>
                          <div class="width-100 flex justify-center mb-16">
                            <b-form-select
                              v-model="selectYear.adminduk"
                              :options="yearOptions"
                              return-object
                              class="col-md-4 col-lg-3"
                            ></b-form-select>
                          </div>
                          <adminduk
                            :filter_tahun="selectYear.adminduk"
                            v-if="tabactive_2"
                          ></adminduk>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab
                    title="Data Kepala Keluarga"
                    @click="chekedfunction(4)"
                  >
                    <b-card-text>
                      <div class="width-95 mt-10">
                        <span
                          class="
                            font-24
                            text-primary text-center
                            width-100
                            pl-4
                            mb-10
                            d-inline-block
                          "
                        >
                          <strong
                            >DATA KEPALA KELUARGA TAHUN
                            {{
                              selectYear.kepala_keluarga == "all"
                                ? "2020-2022"
                                : selectYear.kepala_keluarga
                            }}</strong
                          >
                        </span>
                        <div class="width-100 flex justify-center mb-16">
                          <b-form-select
                            v-model="selectYear.kepala_keluarga"
                            :options="yearOptions"
                            return-object
                            class="col-md-4 col-lg-3"
                          ></b-form-select>
                        </div>
                        <grapichkk
                          :filter_tahun="selectYear.kepala_keluarga"
                          v-if="tabactive_4"
                        ></grapichkk>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Partisipasi Sekolah" @click="chekedfunction(5)">
                    <b-card-text>
                      <div class="row text-center" v-if="tabactive_5">
                        <div class="width-95 d-inline-block">
                          <h4 class="mb-20 mt-10 font-24 text-primary">
                            <strong
                              >Data Partisipasi Sekolah Tahun
                              {{
                                selectYear.partisipasi_sekolah == "all"
                                  ? "2020-2022"
                                  : selectYear.partisipasi_sekolah
                              }}</strong
                            >
                          </h4>
                          <div class="width-100 flex justify-center mb-16">
                            <b-form-select
                              v-model="selectYear.partisipasi_sekolah"
                              :options="yearOptions"
                              return-object
                              class="col-md-4 col-lg-3"
                            ></b-form-select>
                          </div>
                          <!-- <partisipasi></partisipasi> -->
                          <table class="table table-striped w-80">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Kelompok Berdasarkan Umur</th>
                                <th scope="col">Belum Pernah Sekolah</th>
                                <th scope="col">Masih Sekolah</th>
                                <th scope="col">Tidak sekolah lagi</th>
                                <th scope="col">Penyandang Disabilitas</th>
                                <th scope="col">Kepemilikan akte kelahiran</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template
                                v-for="(
                                  value, name, index
                                ) in partisipasi_sekolah"
                              >
                                <tr v-bind:key="index">
                                  <th scope="row">{{ ++index }}</th>
                                  <td>
                                    <strong>{{ name }}</strong>
                                  </td>
                                  <td>{{ value.belum_sekolah }}</td>
                                  <td>{{ value.masih_sekolah }}</td>
                                  <td>{{ value.tidak_sekolah_lagi }}</td>
                                  <td>{{ value.disabilitas }}</td>
                                  <td>{{ value.punya_akta_lahir }}</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab
                    title="Program Bantuan Sosial"
                    @click="chekedfunction(6)"
                  >
                    <b-card-text>
                      <div class="width-95 mt-10">
                        <span
                          class="
                            font-24
                            text-primary text-center
                            pl-4
                            mb-10
                            d-inline-block
                            w-full
                          "
                        >
                          <strong
                            >Program Bantuan Sosial Tahun
                            {{
                              selectYear.bansos == "all"
                                ? "2020-2022"
                                : selectYear.bansos
                            }}</strong
                          >
                        </span>
                        <div class="width-100 flex justify-center mb-16">
                          <b-form-select
                            v-model="selectYear.bansos"
                            :options="yearOptions"
                            return-object
                            class="col-md-4 col-lg-3"
                          ></b-form-select>
                        </div>
                        <bansos
                          :filter_tahun="selectYear.bansos"
                          v-if="this.tabactive_6"
                        ></bansos>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Kepemilikan Rumah" @click="chekedfunction(7)">
                    <b-card-text>
                      <div class="row text-center">
                        <div class="width-95 d-inline-block">
                          <h4 class="mb-20 mt-10 font-24 text-primary">
                            <strong
                              >Data Kepemilikan Rumah Tahun
                              {{
                                selectYear.kepemilikan_rumah == "all"
                                  ? "2020-2022"
                                  : selectYear.kepemilikan_rumah
                              }}</strong
                            >
                          </h4>
                          <div class="width-100 flex justify-center mb-16">
                            <b-form-select
                              v-model="selectYear.kepemilikan_rumah"
                              :options="yearOptions"
                              return-object
                              class="col-md-4 col-lg-3"
                            ></b-form-select>
                          </div>
                          <kepemilikanrumah
                            :filter_tahun="selectYear.kepemilikan_rumah"
                            v-if="this.tabactive_7"
                          ></kepemilikanrumah>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab
                    title="Status Tempat Tinggal"
                    @click="chekedfunction(8)"
                  >
                    <b-card-text>
                      <div class="row text-center">
                        <div class="width-95 d-inline-block">
                          <h4 class="mb-20 mt-10 font-24 text-primary">
                            <strong>
                              Data Kepemilikan Tempat Tinggal vs Kepemilikan
                              Tanah
                              <br />Tempat Tinggal Tahun
                              {{
                                selectYear.status_tempat_tinggal == "all"
                                  ? "2020-2022"
                                  : selectYear.status_tempat_tinggal
                              }}
                            </strong>
                          </h4>
                          <div class="width-100 flex justify-center mb-16">
                            <b-form-select
                              v-model="selectYear.status_tempat_tinggal"
                              :options="yearOptions"
                              return-object
                              class="col-md-4 col-lg-3"
                            ></b-form-select>
                          </div>
                          <statustempat
                            :filter_tahun="selectYear.status_tempat_tinggal"
                            v-if="this.tabactive_8"
                          ></statustempat>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab
                    title="Tingkat Pendidikan Terakhir"
                    @click="chekedfunction(9)"
                  >
                    <b-card-text>
                      <div class="row text-center">
                        <div class="width-95 d-inline-block">
                          <h4 class="mb-20 mt-10 font-24 text-primary">
                            <strong
                              >TINGKAT PENDIDIKAN TERAKHIR TAHUN
                              {{
                                selectYear.tingkat_pendidikan == "all"
                                  ? "2020-2022"
                                  : selectYear.tingkat_pendidikan
                              }}</strong
                            >
                          </h4>
                          <div class="width-100 flex justify-center mb-16">
                            <b-form-select
                              v-model="selectYear.tingkat_pendidikan"
                              :options="yearOptions"
                              return-object
                              class="col-md-4 col-lg-3"
                            ></b-form-select>
                          </div>
                          <TingkatPendidikan
                            :filter_tahun="selectYear.tingkat_pendidikan"
                            v-if="this.tabactive_9"
                          ></TingkatPendidikan>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab
                    title="Jenis Lantai Terluas"
                    @click="chekedfunction(10)"
                  >
                    <b-card-text>
                      <div class="row text-center">
                        <div class="width-95 d-inline-block">
                          <h4 class="mb-20 mt-10 font-24 text-primary">
                            <strong
                              >Data Jenis Lantai Terluas Tahun
                              {{
                                selectYear.jenis_lantai == "all"
                                  ? "2020-2022"
                                  : selectYear.jenis_lantai
                              }}</strong
                            >
                          </h4>
                          <div class="width-100 flex justify-center mb-16">
                            <b-form-select
                              v-model="selectYear.jenis_lantai"
                              :options="yearOptions"
                              return-object
                              class="col-md-4 col-lg-3"
                            ></b-form-select>
                          </div>
                          <JenisLantai
                            :filter_tahun="selectYear.jenis_lantai"
                            v-if="this.tabactive_10"
                          ></JenisLantai>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab
                    title="Jenis Dinding Terluas"
                    @click="chekedfunction(11)"
                  >
                    <b-card-text>
                      <div class="row text-center">
                        <div class="width-95 d-inline-block">
                          <h4 class="mb-20 mt-10 font-24 text-primary">
                            <strong
                              >Data Jenis Dinding Terluas Tahun
                              {{
                                selectYear.jenis_dinding == "all"
                                  ? "2020-2022"
                                  : selectYear.jenis_dinding
                              }}</strong
                            >
                          </h4>
                          <div class="width-100 flex justify-center mb-16">
                            <b-form-select
                              v-model="selectYear.jenis_dinding"
                              :options="yearOptions"
                              return-object
                              class="col-md-4 col-lg-3"
                            ></b-form-select>
                          </div>
                          <JenisDinding
                            :filter_tahun="selectYear.jenis_dinding"
                            v-if="this.tabactive_11"
                          ></JenisDinding>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Jenis Atap Terluas" @click="chekedfunction(3)">
                    <b-card-text>
                      <div class="row text-center">
                        <div class="width-95 d-inline-block">
                          <h4 class="mb-20 mt-10 font-24 text-primary">
                            <strong
                              >Data Jenis Atap Terluas Tahun
                              {{
                                selectYear.jenis_atap == "all"
                                  ? "2020-2022"
                                  : selectYear.jenis_atap
                              }}</strong
                            >
                          </h4>
                          <div class="width-100 flex justify-center mb-16">
                            <b-form-select
                              v-model="selectYear.jenis_atap"
                              :options="yearOptions"
                              return-object
                              class="col-md-4 col-lg-3"
                            ></b-form-select>
                          </div>
                          <JenisAtap
                            :filter_tahun="selectYear.jenis_atap"
                            v-if="this.tabactive_3"
                          ></JenisAtap>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Sumber Air Minum" @click="chekedfunction(12)">
                    <b-card-text>
                      <div class="row text-center">
                        <div class="width-95 d-inline-block">
                          <h4 class="mb-20 mt-10 font-24 text-primary">
                            <strong
                              >Data Sumber dan Cara Mendapatkan Air Minum Tahun
                              {{
                                selectYear.sumber_air == "all"
                                  ? "2020-2022"
                                  : selectYear.sumber_air
                              }}</strong
                            >
                          </h4>
                          <div class="width-100 flex justify-center mb-16">
                            <b-form-select
                              v-model="selectYear.sumber_air"
                              :options="yearOptions"
                              return-object
                              class="col-md-4 col-lg-3"
                            ></b-form-select>
                          </div>
                          <AirBersih
                            :filter_tahun="selectYear.sumber_air"
                            v-if="this.tabactive_12"
                          ></AirBersih>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Sumber Penerangan" @click="chekedfunction(13)">
                    <b-card-text>
                      <div class="row text-center">
                        <div class="width-95 d-inline-block">
                          <h4 class="mb-20 mt-10 font-24 text-primary">
                            <strong
                              >Data Sumber Penerangan Tahun
                              {{
                                selectYear.sumber_penerangan == "all"
                                  ? "2020-2022"
                                  : selectYear.sumber_penerangan
                              }}</strong
                            >
                          </h4>
                          <div class="width-100 flex justify-center mb-16">
                            <b-form-select
                              v-model="selectYear.sumber_penerangan"
                              :options="yearOptions"
                              return-object
                              class="col-md-4 col-lg-3"
                            ></b-form-select>
                          </div>
                          <SumberPenerangan
                            :filter_tahun="selectYear.sumber_penerangan"
                            v-if="tabactive_13"
                          ></SumberPenerangan>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                  <b-tab title="Bahan Bakar" @click="chekedfunction(14)">
                    <b-card-text>
                      <div class="row text-center">
                        <div class="width-95 d-inline-block">
                          <h4 class="mb-20 mt-10 font-24 text-primary">
                            <strong>
                              Data Bahan Bakar Utama untuk Memasak Tahun
                              {{
                                selectYear.bahan_bakar == "all"
                                  ? "2020-2022"
                                  : selectYear.bahan_bakar
                              }}
                            </strong>
                          </h4>
                          <div class="width-100 flex justify-center mb-16">
                            <b-form-select
                              v-model="selectYear.bahan_bakar"
                              :options="yearOptions"
                              return-object
                              class="col-md-4 col-lg-3"
                            ></b-form-select>
                          </div>
                          <BahanBakar
                            :filter_tahun="selectYear.bahan_bakar"
                            v-if="tabactive_14"
                          ></BahanBakar>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </div>
          </div>
        </div>
      </section>
      <section class="card shadow pad-10 mb-80 mt-10 border-radius-5">
        <span
          class="
            d-block
            width-100
            mt-40
            text-center
            font-20 font-weight-8
            text-primary
            mb-10
          "
          >DATA KEPENDUDUKAN PROVINSI PAPUA</span
        >
        <div class="width-100 flex justify-center mb-16">
          <b-form-select
            v-model="selectYear.data_kependudukan"
            :options="yearOptions"
            return-object
            class="col-md-4 col-lg-3"
          ></b-form-select>
        </div>
        <div class="col-md-12 text-right mb-20">
          <b-button @click="exportExcel" variant="outline-primary"
            ><b-icon icon="download" aria-hidden="true"></b-icon> Download
            Excel</b-button
          >
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nama Kota/Kabupaten</th>
              <th scope="col">Jumlah Distrik</th>
              <th scope="col">Total Jiwa</th>
              <th scope="col">Total KK</th>
              <th scope="col">Laki Laki</th>
              <th scope="col">Perempuan</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(data, index) in data_provinsi">
              <tr v-bind:key="index">
                <th scope="row">{{ ++index }}</th>
                <td>
                  <a
                    class="text-primary font-weight-6 cursor-pointer"
                    @click="routerKabuaten(data.id)"
                    >{{ data.nama }}</a
                  >
                </td>
                <td>
                  <a
                    class="text-primary font-weight-6 cursor-pointer"
                    @click="showModalDetail(data.id)"
                    >{{ data.jumlah_distrik }} Distrik</a
                  >
                </td>
                <td>{{ data.total_jiwa }} Jiwa</td>
                <td>{{ data.total_keluarga }}</td>
                <td>{{ data.laki_laki }}</td>
                <td>{{ data.perempuan }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </section>
    </div>
    <b-modal id="modal_detail" size="xl" title="Data Distrik ">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nama Distrik</th>
            <th scope="col">Jumlah Desa</th>
            <th scope="col">Total Jiwa</th>
            <th scope="col">Total KK</th>
            <th scope="col">Laki Laki</th>
            <th scope="col">Perempuan</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(district, index) in data_district" v-bind:key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td class="cursor-pointer">
              <span
                class="d-inline-block w-100 font-weight-6 text-primary"
                @click="routerDesa(district.id, district.nama)"
                >{{ district.nama }}
              </span>
            </td>
            <td>
              <span class="font-weight-6"
                >{{ district.jumlah_kampung }} Desa</span
              >
            </td>
            <td>{{ district.total_jiwa }} Jiwa</td>
            <td>{{ district.total_keluarga }}</td>
            <td>{{ district.laki_laki }}</td>
            <td>{{ district.perempuan }}</td>
          </tr>
        </tbody>
      </table>
      <!-- <span class="d-inline-block w-full float-right ">Source: papua.bps.go.id</span> -->
    </b-modal>
  </div>
</template>
<script>
import JiwaSummary from "../../../components/JiwaSummary";
import ChartSummary from "../../../components/ChartSummary";
import PieChart from "./components/pieChart";
import grapichkk from "./components/grafik-kk";
// import GrowthChart from "./components/growthChart";
import TingkatPendidikan from "./components/TingkatPendidikan";
import adminduk from "./components/adminduk";
import MapsComponent from "./components/mapSVG2";
import bansos from "./components/bansos";
import kepemilikanrumah from "./components/kepemilikanrumah";
import statustempat from "./components/statustempattinggal";
import JenisLantai from "./components/jenislantai";
import JenisDinding from "./components/jenisdinding";
import JenisAtap from "./components/jenisatap";
import AirBersih from "./components/airbersih";
import SumberPenerangan from "./components/sumberpenerangan";
import BahanBakar from "./components/bahanbakar";
import XLSX from "xlsx";

export default {
  components: {
    JiwaSummary,
    ChartSummary,
    // GrowthChart,
    TingkatPendidikan,
    PieChart,
    MapsComponent,
    grapichkk,
    adminduk,
    bansos,
    kepemilikanrumah,
    statustempat,
    JenisLantai,
    JenisDinding,
    JenisAtap,
    AirBersih,
    SumberPenerangan,
    BahanBakar,
  },
  name: "data-kependudukan",
  data() {
    return {
      selected1: null,
      options1: [
        { value: null, text: "Tahun 2022" },
        { value: "a", text: "Tahun 2022" },
      ],
      dataOAP: [],
      total_pria: 0,
      total_perempuan: 0,
      tabactive_1: true,
      tabactive_2: false,
      tabactive_3: false,
      tabactive_4: false,
      tabactive_5: false,
      tabactive_6: false,
      tabactive_7: false,
      tabactive_8: false,
      tabactive_9: false,
      tabactive_10: false,
      tabactive_11: false,
      tabactive_12: false,
      tabactive_13: false,
      tabactive_14: false,
      total_kk: 0,
      total_jiwa: 0,
      partisipasi_sekolah: {},
      data_provinsi: [],
      data_district: [],
      dataready: false,
      maps_color: [
        "#024F46",
        "#DD9FFF",
        "#FFCC00",
        "#5FD3BC",
        "#AC93A7",
        "#DA756C",
        "#009388",
        "#37ABC8",
        "#935C02",
        "#D64361",
        "#992B00",
        "#8D5FD3",
        "#CC820D",
        "#00AB37",
        "#A8C400",
        "#FF8500",
        "#4074AD",
        "#AA9902",
        "#D64607",
        "#813087",
        "#008066",
        "#8CAC89",
        "#557AFF",
        "#AC7F6B",
        "#065484",
        "#DDAF8E",
        "#DE87AA",
        "#D60000",
        "#87AADE",
      ],
      export: {
        data_provinsi: [],
      },
      selectYear: {
        persebaran: "all",
        oap: "all",
        adminduk: "all",
        kepala_keluarga: "all",
        partisipasi_sekolah: "all",
        data_kependudukan: "all",
        bansos: "all",
        kepemilikan_rumah: "all",
        status_tempat_tinggal: "all",
        tingkat_pendidikan: "all",
        jenis_lantai: "all",
        jenis_dinding: "all",
        jenis_atap: "all",
        sumber_air: "all",
        sumber_penerangan: "all",
        bahan_bakar: "all",
      },
      yearOptions: [
        {
          text: "Tahun 2020-2022",
          value: "all",
        },
        {
          text: "Tahun 2020",
          value: "2020",
        },
        {
          text: "Tahun 2021",
          value: "2021",
        },
        {
          text: "Tahun 2022",
          value: "2022",
        },
      ],
    };
  },
  async mounted() {
    this.$store.commit("SET_BG", true);
    await this.getTotalJenisPenduduk();
    await this.getDataPartisipasiSekolah();
    await this.getDataProvinsi();
    await this.persebaranOAP();
    this.dataready = true;
  },
  watch: {
    "selectYear.persebaran"(newVal) {
      this.changePersebaranOAP(newVal);
    },
    "selectYear.partisipasi_sekolah"(newVal) {
      this.changeDataPartisipasiSekolah(newVal);
    },
    "selectYear.data_kependudukan"(newVal) {
      this.changeDataProvinsi(newVal);
    },
  },
  methods: {
    chekedfunction(newVal) {
      this.tabactive_1 = false;
      this.tabactive_2 = false;
      this.tabactive_3 = false;
      this.tabactive_4 = false;
      this.tabactive_5 = false;
      this.tabactive_6 = false;
      this.tabactive_7 = false;
      this.tabactive_8 = false;
      this.tabactive_9 = false;
      this.tabactive_10 = false;
      this.tabactive_11 = false;
      this.tabactive_12 = false;
      this.tabactive_13 = false;
      this.tabactive_14 = false;
      if (newVal == 1) {
        this.tabactive_1 = true;
      }
      if (newVal == 2) {
        this.tabactive_2 = true;
      }
      if (newVal == 3) {
        this.tabactive_3 = true;
      }
      if (newVal == 4) {
        this.tabactive_4 = true;
      }
      if (newVal == 5) {
        this.tabactive_5 = true;
      }
      if (newVal == 6) {
        this.tabactive_6 = true;
      }
      if (newVal == 7) {
        this.tabactive_7 = true;
      }
      if (newVal == 8) {
        this.tabactive_8 = true;
      }
      if (newVal == 9) {
        this.tabactive_9 = true;
      }
      if (newVal == 10) {
        this.tabactive_10 = true;
      }
      if (newVal == 11) {
        this.tabactive_11 = true;
      }
      if (newVal == 12) {
        this.tabactive_12 = true;
      }
      if (newVal == 13) {
        this.tabactive_13 = true;
      }
      if (newVal == 14) {
        this.tabactive_14 = true;
      }
    },
    getTotalJenisPenduduk() {
      this.$axios.get("/v1/sensus/jiwa-kk-pria-wanita").then((response) => {
        let result = response.data;
        this.total_pria = result.pria.semua;
        this.total_perempuan = result.wanita.semua;
        this.total_jiwa = result.jiwa.semua;
        this.total_kk = result.kartu_keluarga.semua;
      });
    },
    getDataPartisipasiSekolah() {
      this.$axios.get("/v1/sensus/partisipasi-sekolah").then((response) => {
        let result = response.data;
        this.partisipasi_sekolah = result;
      });
    },
    changeDataPartisipasiSekolah(filter_tahun) {
      this.$axios
        .post("/data-partisipasi-sekolah", null, {
          params: {
            filter_tahun: filter_tahun,
          },
        })
        .then((response) => {
          let result = response.data;
          this.partisipasi_sekolah = result;
        });
    },
    showModalDetail(district_id) {
      this.$axios
        .get("/v1/sensus/city/kependudukan/" + district_id)
        .then((response) => {
          let result = response.data;
          this.data_district = result;
          this.$bvModal.show("modal_detail");
        });
    },
    persebaranOAP() {
      this.$axios.get("/v1/sensus/persebaran-oap").then((response) => {
        this.dataOAP = response.data;
        console.log(this.dataOAP);
        //  console.log(response.data)
      });
    },
    changePersebaranOAP(filter_tahun) {
      this.$axios
        .post("/data-persebaran-oap", null, {
          params: {
            filter_tahun: filter_tahun,
          },
        })
        .then((response) => {
          this.dataOAP = response.data;
          console.log(this.dataOAP);
          //  console.log(response.data)
        });
    },
    routerKabuaten(id) {
      this.$router.push("/data-kabupaten/" + id);
    },
    routerDesa(id, name) {
      this.$router.push("/data-desa/" + id);
      this.$store.commit("DISTRICT_STATE", name);
    },
    getDataProvinsi() {
      this.$axios.get("/v1/sensus/kependudukan-provinsi").then((response) => {
        let result = response.data;
        let i = 0;
        result.forEach((e) => {
          this.data_provinsi.push({
            id: e.id,
            jumlah_distrik: e.jumlah_distrik,
            laki_laki: e.laki_laki,
            nama: e.nama,
            perempuan: e.perempuan,
            total_jiwa: e.total_jiwa,
            total_keluarga: e.total_keluarga,
            color: this.maps_color[i],
          });
          this.export.data_provinsi.push({
            No: e.id,
            "Nama Kota/Kabupaten": e.nama,
            "Jumlah Distrik": e.jumlah_distrik,
            "Total Jiwa": e.total_jiwa,
            "Total KK": e.total_keluarga,
            "Laki-laki": e.laki_laki,
            Perempuan: e.perempuan,
          });
          i++;
        });
      });
    },
    changeDataProvinsi(filter_tahun) {
      this.$axios
        .post("/data-kependudukan", null, {
          params: {
            filter_tahun: filter_tahun,
          },
        })
        .then((response) => {
          let result = response.data;
          let i = 0;
          this.data_provinsi = [];
          this.export.data_provinsi = [];
          result.forEach((e) => {
            this.data_provinsi.push({
              id: e.id,
              jumlah_distrik: e.jumlah_distrik,
              laki_laki: e.laki_laki,
              nama: e.nama,
              perempuan: e.perempuan,
              total_jiwa: e.total_jiwa,
              total_keluarga: e.total_keluarga,
              color: this.maps_color[i],
            });

            this.export.data_provinsi.push({
              No: e.id,
              "Nama Kota/Kabupaten": e.nama,
              "Jumlah Distrik": e.jumlah_distrik,
              "Total Jiwa": e.total_jiwa,
              "Total KK": e.total_keluarga,
              "Laki-laki": e.laki_laki,
              Perempuan: e.perempuan,
            });
            i++;
          });
        });
    },
    exportExcel() {
      // On Click Excel download button

      // export json to Worksheet of Excel
      // only array possible

      // A workbook is the name given to an Excel file
      // make Workbook of Excel
      let objectMaxLength = [];
      for (let i = 0; i < this.export.data_provinsi.length; i++) {
        let value = Object.values(this.export.data_provinsi[i]);
        for (let j = 0; j < value.length; j++) {
          if (value[j] !== undefined) {
            if (typeof value[j] == "number") {
              objectMaxLength[j] = 15;
            } else {
              objectMaxLength[j] =
                objectMaxLength[j] >= value[j].length
                  ? (objectMaxLength[j] += 2)
                  : value[j].length;
            }
          } else {
            objectMaxLength[j] = 20;
          }
        }
      }

      var keluargaWscols = [
        { width: objectMaxLength[0] },
        { width: 35 },
        { width: objectMaxLength[2] },
        { width: objectMaxLength[3] },
        { width: objectMaxLength[4] },
        { width: objectMaxLength[5] },
        { width: objectMaxLength[6] },
      ];

      var keluargaWS = XLSX.utils.json_to_sheet(this.export.data_provinsi);
      var wb = XLSX.utils.book_new();

      // export Excel file
      setTimeout(() => {
        keluargaWS["!cols"] = keluargaWscols;

        // add Worksheet to Workbook
        // Workbook contains one or more worksheets
        XLSX.utils.book_append_sheet(wb, keluargaWS, "Data Kota dan Kabupaten"); // sheetAName is name of Worksheet
        XLSX.writeFile(
          wb,
          "Data Kependudukan Provinsi Papua - " + new Date() + ".xlsx"
        ); // name of the file is 'book.xlsx'
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-container {
  background-color: #fdfdfd;
  height: 720px;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 4px 28px rgba(123, 151, 158, 0.25);
  border: 1px solid #d6dee1;
  padding: 1rem;
  overflow: scroll;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>
